'use strict';

jQuery(function ($) {
	// Filtres
	// $('.filtres a').click(function (e) {
	// 	e.preventDefault();
	// 	$(this).addClass('active').siblings().removeClass('active');
	// 	var a = $(this).attr('href');
	// 	a = a.substr(1);
	// 	$('.sets a').each(function () {
	// 		if (!$(this).hasClass(a) && a != 'all') $(this).parent().addClass('hide');else $(this).parent().removeClass('hide');
	// 	});
	// });

	// Accordion pour les articles.
	// $('.accordion-toggle.collapse.in').removeClass('in').css('height', '');
	// $('.accordion-heading').on('click', function (e) {
	// 	e.preventDefault();
	// 	e.stopPropagation();
	// 	var $showed = $('.accordion-body.show');
	// 	$(this).parent().addClass('actif').siblings().removeClass('actif');
	// 	$(this).next('.accordion-body').toggleClass('show').css('height', '');
	// 	if ($(this).parent().index() !== $showed.parent().index()) {
	// 		$showed.toggleClass('show').css('height', '');
	// 		$(this).parent().addClass('actif');
	// 	} else {
	// 		$(this).parent().removeClass('actif');
	// 	}
	// });

});
